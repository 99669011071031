










































































































































.waitlist-container-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.waitlist-container-form *{
    color: var(--color-text-primary);
}
.value-input{
    width: 400px;
    margin: 15px 0 15px 0;
}
.submit-form-button{
    margin-top: 15px;
}

